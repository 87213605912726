import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Car } from 'src/app/models/car';
import { CarFilter } from 'src/app/models/carFilter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CarService {
  private apiUrl = environment.apiUrl + '/public/cars';

  constructor(private http: HttpClient) { }

  // Fetch filtered paginated cars
  findFilteredAllCars(
    page: number,
    size: number,
    sortBy: string,
    filters: CarFilter
  ): Observable<Car[]> {
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('sortBy', sortBy);

      return this.http.post<Car[]>(this.apiUrl + '/filter', filters, { params });
  }

  findSearchedAllCars(
    page: number,
    size: number,
    sortBy: string,
    filters: CarFilter
  ): Observable<Car[]> {
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('sortBy', sortBy);
    
      return this.http.post<Car[]>(this.apiUrl + '/search', filters, { params });
  }


  // Get total count of filtered cars
  getTotalFilteredCarCount(filters: CarFilter): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/count-filtered`, filters);
  }

    // Get total count of filtered cars
    getTotalSearchedCarCount(filters: CarFilter): Observable<number> {
      return this.http.post<number>(`${this.apiUrl}/count-searched`, filters);
    }
}
