import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const modifiedReq = req.clone({
        headers: req.headers
          .set('X-API-KEY', 'talabcar@06/08/2024=@@@2345')
      });
        
    return next(modifiedReq);
  }