import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from './shared/shared.module';

import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { authInterceptor } from './interceptors/auth';

import { initializeApp } from 'firebase/app';

import { NgxSpinnerModule } from "ngx-spinner";
import { ListingDetailsComponent } from './components/car-details/car-details.component';
import { ListingGridComponent } from './components/car-list/car-list.component';

initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ListingGridComponent,
    ListingDetailsComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxSpinnerModule,

  ],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor]))
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
