import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsDatepickerConfig, BsDaterangepickerConfig, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { routes } from 'src/app/shared/routes/routes';
import { DataService } from 'src/app/shared/services/data/data.service';
import { carTypes, testimonial, testimonials, thumbnails1 } from 'src/app/shared/services/model/model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements AfterViewInit {
  public routes = routes;

  public carTypes: carTypes[] = [];
  public testimonials: testimonials[] = [];
  public testimonial: testimonial[] = [];
  public thumbnails1: thumbnails1[] = [];
  
  public cities: string[] = ['Casablanca', 'Marrakech', 'Rabat', 'Tangier', 'Agadir', 'Fes', 'Meknes','Taounate'];

  public selectedLocation: string | null = null;
  public selectedDateRange: string | null = null;
  public startDate: Date | undefined;
  public endDate: Date | undefined;

  public isLocationFieldFocused = false;
  public isDateFieldFocused = false;

  public readonly bsConfig:Partial<BsDaterangepickerConfig> = {
    minDate: new Date(),
    adaptivePosition: true,
    rangeInputFormat: 'MMM D',
    useUtc:true,
    isAnimated:true, 
    startView:'month',
    returnFocusToInput:false,
    maxDateRange:90,//max is 90 days
  }



  @ViewChild('dateRangePickerInput') dateRangePickerInput!: BsDaterangepickerDirective;
  @ViewChild('selectedLocationInput',{static:false}) selectedLocationInput!:ElementRef; 
  @ViewChild('selectedDateRangeInput',{static:false}) selectedDateRangeInput!:ElementRef; 

  constructor(private data: DataService,private router : Router) {
    this.testimonials = this.data.testimonials;
    this.thumbnails1 = this.data.thumbnails1;
    this.testimonial = this.data.testimonial;
    this.carTypes = this.data.carTypes;

    this.setDefaultDateRange();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  // Utility methods
  private formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  private setDefaultDateRange(): void {
    const today = new Date();
    const threeDaysLater = new Date(today);
    threeDaysLater.setDate(today.getDate() + 3);

    this.startDate = today;
    this.endDate = threeDaysLater;
    this.updateDateRangeDisplay();
  }

  carOwlOptions: OwlOptions = {
    loop:true,
    margin:24,
    nav:true,
    dots: false,
    autoplay:false,
    navText : ["<i class='bx bx-chevron-left'></i>","<i class='bx bx-chevron-right'></i>"],
    responsive:{
      0:{
        items:1
      },
      
      550:{
        items:1
      },
      700:{
        items:2
      },
      1000:{
        items:7
      },
      1200:{
        items:7
      }
    }
  };

  carTypesOptions: OwlOptions = {
    loop: true,
    margin: 24,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    navText: [
      "<i class='fa-solid fa-arrow-left'></i>",
      "<i class='fa-solid fa-arrow-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },

      550: {
        items: 1,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
  };

  ProfessionalOwlOptions: OwlOptions = {
    loop:true,
			margin:24,
			nav:true,
			dots:true,
			autoplay:false,
      navText : ["<i class='bx bx-chevron-left'></i>","<i class='bx bx-chevron-right'></i>"],
      responsive:{
				0:{
					items:1
				},				
				550:{
					items:3
				},
				768:{
					items:4
				},
				1000:{
					items:5
				},
				1200:{
					items:5

				}
			}
  };

  private updateDateRangeDisplay(): void {
    if (this.startDate && this.endDate) {
      this.selectedDateRange = `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
    } else {
      this.selectedDateRange = 'Add dates';
    }
  }

  // Event Handlers
  public onSelect(event: any): void {
  }

  public onLocationFieldFocus(): void {
    this.isLocationFieldFocused = true;
  }

  public onLocationFieldBlur(): void {
    this.isLocationFieldFocused = false;
  }

  public onDateFieldFocus(): void {
    this.isDateFieldFocused = true;
  }

  public onDateFieldBlur(): void {
    this.isDateFieldFocused = false;
  }

  public onDateSelected(value: (Date | undefined)[] | undefined): void {
    if (value && value.length === 2 && value[0] && value[1]) {
      this.startDate = value[0];
      this.endDate = value[1];
      this.updateDateRangeDisplay();
    }
  }

  public onLocationSelect(event: any): void {
    this.selectedLocation = event.item;
  }

  public clearLocation(event: Event): void {
    event.preventDefault();
    this.selectedLocation = null;
  }

  public clearDateRange(): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.selectedDateRange = 'Add dates';
  }
  public isClassAdded: boolean[] = [false];
 toggleClass(index: number) {
    this.isClassAdded[index] = !this.isClassAdded[index];
  }

  public search(): void {
    if (!this.selectedLocation) {
      console.error('Location is required.');
      this.selectedLocationInput.nativeElement.click();
      return;
    }
    
    if (!this.startDate || !this.endDate) {
      this.selectedDateRangeInput.nativeElement.click();
      console.error('Date range is required.');
      return;
    }

    this.router.navigate(['/listing'],{
      queryParams:{
        city:this.selectedLocation,
        startDate:this.startDate,
        endDate:this.endDate
      }
    })
  }
}
