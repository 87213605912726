import { Component, OnInit } from '@angular/core';
import * as Aos from 'aos';


import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CommonService } from './shared/services/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl:'./app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'talabcar';

  public strokeValue = 0;
  public progress = 0;

  
  base = '';
  page = '';
  last = '';
  currentUrl = '';

  constructor(
    private common: CommonService,
    private translate: TranslateService,
  ) {
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');

    //get base
    {
      this.common.base.subscribe((res: string) => {
        this.base = res;
      });
      this.common.page.subscribe((res: string) => {
        this.page = res;
      });
      this.common.last.subscribe((res: string) => {
        this.last = res;
      });
    }
  }


  ngOnInit() {
    console.log('profile: ' + environment.profile);

    this.calculateScrollPercentage();
    Aos.init({
      duration: 1200,
      once: true,
    });
  }


  // scroll the page to top position
  public scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  // function to calculate the scroll progress
  private calculateScrollPercentage(): void {
    window.addEventListener('scroll', () => {
      const body = document.body,
        html = document.documentElement;
      //gets the total height of page till scroll
      const totalheight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      // calculates the total stroke value
      this.progress = totalheight;
      const currentDistance = window.scrollY / (totalheight / 3000);
      // calculates the current stroke value
      this.strokeValue = totalheight - currentDistance / 8;
      // condition to hide scroll progress if page in top
      if (window.scrollY == 0) {
        this.strokeValue = totalheight;
      }
      // condition to make scroll progress to 100 if page in bottom
      if (window.innerHeight + window.scrollY >= totalheight) {
        this.strokeValue = 0;
      }
    });
  }
}
