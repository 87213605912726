export const environment = {
    profile:"dev",
    production: false,
    apiUrl:"/api/dev",
    firebaseConfig : {
      apiKey: "AIzaSyBYuPPwjzj22Cmdzd2seGtmvrnc6KaRass",
      authDomain: "talabcar-front.firebaseapp.com",
      projectId: "talabcar-front",
      storageBucket: "talabcar-front.firebasestorage.app",
      messagingSenderId: "307301446269",
      appId: "1:307301446269:web:b6cc8a5b8a0a9548457714",
      measurementId: "G-6Q9DCPWFG4"  
    }
  };
  