<!-- Breadscrumb Section -->
<app-header></app-header>
<div class="breadcrumb-bar">
  <div class="container">
    <div class="row align-items-center text-center">
      <div class="col-md-12 col-12">
        <h2 class="breadcrumb-title">Votre Voiture - Votre Choix</h2>
      </div>
    </div>
  </div>
</div>
<!-- /Breadscrumb Section -->
<!-- Search -->
<!--TODO: to move component here-->
<!-- /Search -->
<!-- Sort By -->
<div class="sort-section mt-4">
  <div class="container">
    <div class="sortby-sec">
      <div class="sorting-div">
        <div class="row d-flex align-items-center">
          <div class="col-xl-4 col-lg-3 col-sm-12 col-12">
            <div class="count-search">
              <p>
                Voitures Affichées : {{ getDisplayedRange() }} sur
                {{ totalCount }}
              </p>
            </div>
          </div>
          <div class="col-xl-8 col-lg-9 col-sm-12 col-12">
            <div class="product-filter-group">
              <div class="sortbyset">
                <ul>
                  <li>
                    <div class="sorting-select select-two">
                      <mat-select
                        [formControl]="sortByControl"
                        class="form-control select custom-select"
                        placeholder="Trié par"
                        panelClass="custom-select-dropdown"
                      >
                        @for (sortBy of sortByList; track sortBy) {
                        <mat-option [value]="sortBy.value">
                          {{ sortBy.label }}
                        </mat-option>
                        }
                      </mat-select>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="grid-listview">
                <ul>
                  <li>
                    <a class="active">
                      <i class="feather icon-grid"></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class="feather icon-list"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Sort By -->

<!-- Car Grid View -->
<section class="section car-listing pt-0">
  <div class="container">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <!-- col -->
          <div
            class="col-xxl-4 col-lg-6 col-md-6 col-12"
            *ngFor="let car of cars" (click)="navigateToCarDetails(car.objectID)"
          >
            <div class="top-rated-card">
              <div class="rated-car-img slide-card-images">
                <div class="image-slider">
                  <owl-carousel-o [options]="carOwlOptions" class="blog-slider">
                    <ng-template carouselSlide>
                      <div class="slide-images">
                        <a>
                          <img
                            src="assets/img/cars/car-card-02.png"
                            class="img-fluid"
                            alt="img"
                          />
                        </a>
                      </div>
                    </ng-template>
                    <ng-template carouselSlide>
                      <div class="slide-images">
                        <a>
                          <img
                            src="assets/img/cars/car-card-01.png"
                            class="img-fluid"
                            alt="img"
                          />
                        </a>
                      </div>
                    </ng-template>
                    <ng-template carouselSlide>
                      <div class="slide-images">
                        <a>
                          <img
                            src="assets/img/cars/car-card-03.png"
                            class="img-fluid"
                            alt="img"
                          />
                        </a>
                      </div>
                    </ng-template>
                  </owl-carousel-o>
                </div>
              </div>
              <div class="rated-car-content">
                <div class="car-content-head">
                  <div class="head-items-left">
                    <h4>
                      <a>{{ car.brand }} - {{car.model}}</a>
                    </h4>
                    <span class="d-flex align-items-center"
                      ><i class="bx bx-map me-2"></i>{{ car.cities[0] ? 'ss':'f' }}</span
                    >
                  </div>
                  <div class="head-items-right">
                    <div class="rated-star">
                      <i class="bx bxs-star filled"></i>
                      <i class="bx bxs-star filled"></i>
                      <i class="bx bxs-star filled"></i>
                      <i class="bx bxs-star filled"></i>
                      <i class="bx bxs-star"></i>
                    </div>
                    <span class="km-badge"
                      ><i class="bx bx-map-pin me-1"></i
                      >{{
                        getDistanceBetweenCarAndUser(car.coordinates)
                      }}
                      km</span
                    >
                  </div>
                </div>
                <div class="car-content-body">
                  <ul class="car-features-info">
                    <li>
                      <span class="car-feature-icon"
                        ><img
                          src="assets/img/icons/yacht-feature-icon-03.svg"
                          alt="Img"
                      /></span>
                      <h6>
                        <span>{{ car.fuelType }}</span>
                      </h6>
                    </li>
                    <li>
                      <span class="car-feature-icon"
                        ><img
                          src="assets/img/icons/yacht-feature-icon-05.svg"
                          alt="Img"
                      /></span>
                      <h6>
                        <span>{{ car.transmission }}</span>
                      </h6>
                    </li>
                    <li>
                      <span class="car-feature-icon"
                        ><img src="assets/img/icons/car-parts-02.svg" alt="Img"
                      /></span>
                      <h6>
                        <span>{{ car.color }} KM</span>
                      </h6>
                    </li>
                  </ul>
                </div>
                <div class="car-content-footer">
                  <p>
                    <span>{{ car.pricePerDay }} $ </span>/Jour
                  </p>
                  <div class="car-book-btn">
                    <a class="btn btn-secondary">Réserver</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Pagination-->
        <div class="blog-pagination">
          <nav>
            <ul class="pagination page-item justify-content-center">
              <li class="previtem" *ngIf="page !== 0">
                <a class="page-link" (click)="prevPage()"
                  ><i class="fas fa-regular fa-arrow-left"></i
                ></a>
              </li>
              <li class="justify-content-center pagination-center">
                <div class="page-group">
                  <ul>
                    <li
                      class="page-item"
                      *ngFor="
                        let pageNumber of createArrayFromNumber(totalPages)
                      "
                    >
                      <a
                        [ngClass]="{ 'active ': page === pageNumber }"
                        class="page-link"
                        (click)="goToPage(pageNumber)"
                        >{{ pageNumber + 1
                        }}<span class="visually-hidden">(current)</span></a
                      >
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nextlink">
                <a
                  class="page-link"
                  *ngIf="page !== createArrayFromNumber(totalPages).length - 1"
                  (click)="nextPage()"
                  ><i class="fas fa-regular fa-arrow-right"></i
                ></a>
              </li>
            </ul>
          </nav>
        </div>
        <!--/Pagination-->
      </div>
      <div class="col-lg-3 col-12 theiaStickySidebar">
        <div class="stickybar">
          <form
            [formGroup]="filterForm"
            autocomplete="off"
            class="sidebar-form"
          >
            <!-- Search Query -->
            <div class="sidebar-heading">
              <h3>Que recherchez-vous ?</h3>
            </div>
            <div class="product-search">
              <div class="form-custom">
                <input
                  type="text"
                  class="form-control"
                  id="member_search1"
                  formControlName="searchQuery"
                  placeholder="What's in your mind ?"
                />
                <span><img src="assets/img/icons/search.svg" alt="img" /></span>
              </div>
            </div>

            <!-- Voiture de luxe ? -->
            <div class="product-availability">
              <h6>Uniquement luxe ?</h6>
              <div class="status-toggle">
                <input
                  id="mobile_notifications"
                  class="check"
                  type="checkbox"
                  formControlName="luxe"
                />
                <label for="mobile_notifications" class="checktoggle"
                  >checkbox</label
                >
              </div>
            </div>

            <!-- Brands -->
            <div class="accord-list">
              <div class="accordion" id="accordionMain1">
                <div class="card-header-new" id="headingOne">
                  <h6 class="filter-title">
                    <a
                      href="javascript:void(0);"
                      class="w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Marque Auto
                      <span class="float-end"
                        ><i class="fa-solid fa-chevron-down"></i
                      ></span>
                    </a>
                  </h6>
                </div>
                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="card-body-chat">
                    <div class="row">
                      <div class="col-md-12">
                        <div id="checkBoxes1">
                          <div class="selectBox-cont">
                            <label
                              class="custom_check w-100"
                              *ngFor="let br of brands"
                            >
                              <input
                                type="checkbox"
                                (change)="toggleSelection(selectedBrands, br)"
                              />
                              <span class="checkmark"></span> {{ br }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Fuel Type -->
              <div class="accordion" id="accordionMain04">
                <div class="card-header-new" id="headingfuel">
                  <h6 class="filter-title">
                    <a
                      href="javascript:void(0);"
                      class="w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefuel"
                      aria-expanded="true"
                      aria-controls="collapsefuel"
                    >
                      Type de Carburant
                      <span class="float-end"
                        ><i class="fa-solid fa-chevron-down"></i
                      ></span>
                    </a>
                  </h6>
                </div>
                <div
                  id="collapsefuel"
                  class="collapse show"
                  aria-labelledby="headingfuel"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="card-body-chat">
                    <div class="fuel-list">
                      <ul>
                        <li *ngFor="let fuelType of fuelTypes">
                          <div class="hidden-checkbox input-selection">
                            <input
                            type="checkbox"
                            [id]="fuelType"
                            [value]="fuelType"
                            (change)="onFuelTypeChange($event)"
                            [checked]="filterForm.value.fuelType.includes(fuelType)"
                            />
                            <label [for]="fuelType">{{ fuelType }}</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionMain9">
                <div class="card-header-new" id="headingFour">
                  <h6 class="filter-title">
                    <a
                      href="javascript:void(0);"
                      class="w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                    >
                      Budget [ {{filterForm.get('minPrice')!.value}} $ - {{filterForm.get('maxPrice')!.value}} $ ]
                      <span class="float-end"
                        ><i class="fa-solid fa-chevron-down"></i
                      ></span>
                    </a>
                  </h6>
                </div>
                <div
                  id="collapseFour"
                  class="collapse show"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample4"
                >
                  <div class="card-body-chat">
                    <div class="filter-range">
                      <mat-slider min="100" [step]="50" max="5000">
                        <input formControlName="minPrice" matSliderStartThumb />
                        <input formControlName="maxPrice" matSliderEndThumb />
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionMain8">
                <div class="card-header-new" id="headingThree">
                  <h6 class="filter-title">
                    <a
                      href="javascript:void(0);"
                      class="w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      Capacité
                      <span class="float-end"
                        ><i class="fa-solid fa-chevron-down"></i
                      ></span>
                    </a>
                  </h6>
                </div>
                <div
                  id="collapseThree"
                  class="collapse show"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample3"
                >
                  <div class="card-body-chat">
                    <div id="checkBoxes3">
                      <div class="selectBox-cont">
                        <label
                          class="custom_check w-100"
                          *ngFor="let capacity of capacities"
                        >
                          <input
                            type="checkbox"
                            (change)="
                              toggleSelection(selectedCapacities, capacity)
                            "
                          />
                          <span class="checkmark"></span> {{ capacity }} Places
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionMain4">
                <div class="card-header-new" id="headingtransmiss">
                  <h6 class="filter-title">
                    <a
                      href="javascript:void(0);"
                      class="w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsetransmission"
                      aria-expanded="true"
                      aria-controls="collapsetransmission"
                    >
                      Transmission
                      <span class="float-end"
                        ><i class="fa-solid fa-chevron-down"></i
                      ></span>
                    </a>
                  </h6>
                </div>
                <div
                  id="collapsetransmission"
                  class="collapse show"
                  aria-labelledby="headingtransmiss"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="card-body-chat">
                    <div class="fuel-list">
                      <ul>
                        <li *ngFor="let transmission of transmissions">
                          <div class="hidden-checkbox input-selection">
                            <input
                            type="checkbox"
                            [id]="transmission"
                            [value]="transmission"
                            (change)="onTransmissionChange($event)"
                            [checked]="filterForm.value.transmission.includes(transmission)"
                            />
                            <label [for]="transmission"
                              >{{ transmission }}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>

<!-- /Car Grid View -->
