<div class="main-wrapper">

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#0a91b1" type="ball-scale-multiple">
   <!--  <div class="inline-spinner"></div> -->

  </ngx-spinner>

  <router-outlet></router-outlet>
</div>


<!-- scrollToTop start -->
@if (
base === 'blog' ||
base === 'booking' ||
base === 'home' ||
base === 'listings' ||
base === 'pages' ||
base === 'user'
) {
<div (click)="scrollToTop()" (keydown.enter)="scrollToTop()" role="button" tabindex="0" class="progress-wrap"
  [class.active-progress]="strokeValue < progress">
  <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style="transition: stroke-dashoffset 10ms linear 0s"
      attr.stroke-dasharray="{{ progress + 'px' + ',' + progress + 'px' }}"
      attr.stroke-dashoffset="{{ strokeValue + 'px' }}"></path>
  </svg>
</div>
}


<!-- scrollToTop end -->