<app-header></app-header>

<!-- Breadscrumb Section -->
<div class="breadcrumb-bar d-none">
  <div class="container">
    <div class="row align-items-center text-center">
      <div class="col-md-12 col-12">
        <h2 class="breadcrumb-title">Chevrolet Camaro</h2>
        <nav aria-label="breadcrumb" class="page-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="routes.home">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="javascript:void(0);">Listings</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Chevrolet Camaro
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- /Breadscrumb Section -->

<!-- Detail Page Head-->
<section class="product-detail-head">
  <div class="container">
    <div class="detail-page-head">
      <div class="detail-headings">
        <div class="star-rated">
          <ul class="list-rating">
            <li>
              <div class="car-brand">
                <span>
                  <img src="assets/img/icons/car-icon.svg" alt="img" />
                </span>
                Sedan
              </div>
            </li>
            <li class="ratings">
              <i class="fas fa-star filled"></i>
              <span class="d-inline-block average-list-rating fw-medium">4.5</span>
              <span class="d-inline-block average-list-rating">(112)</span>
            </li>
          </ul>
          <div class="camaro-info">
            <h3>Honda - Civic</h3>
            <div class="camaro-location">
              <div class="camaro-location-inner">
                <i class="bx bx-map"></i>

                <span>Street St, Lorem 32550, Casablanca </span>
              </div>
              <div class="camaro-location-inner">
                <i class="bx bx-calendar"></i>
                <span>Date: 01 Decembre 2024 </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- /Detail Page Head-->

<section class="section product-details">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="detail-product">
          <div class="pro-info">
            <div class="pro-badge">
              <span class="badge-km"><i class="fa-solid fa-star"></i>Voiture de Luxe</span>
            </div>
            <ul>
              <li class="del-home">
                <a href="javascript:void(0);" class="fav-icon"><i class="fa-solid fa-share m-0"></i></a>

              </li>
              <li class="del-home">
                <a href="javascript:void(0);" class="fav-icon"><i class="fa-regular fa-heart m-0"></i></a>

              </li>
            </ul>
          </div>
          <div class="slider">
            <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" data-sizes="50vw"
              class="detail-bigimg">
              @for (data of listingDetails; track data) {
              <div ngxSlickItem class="product-img">
                <img [src]="data.img" alt="Slider" />
              </div>
              }
            </ngx-slick-carousel>
          </div>
          <div class="slider">
            <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig2" data-sizes="50vw"
              class="slider-nav-thumbnails">
              @for (data of listingDetails; track data) {
              <div ngxSlickItem>
                <img [src]="data.img" alt="product image" />
              </div>
              }
            </ngx-slick-carousel>
          </div>
        </div>
        <div class="review-sec extra-service">
          <div class="review-header">
            <h4>Services Supplémentaires</h4>
          </div>
          <div class="lisiting-service">
            <div class="row">
              <div class="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                <div class="service-img">
                  <img src="assets/img/icons/service-01.svg" alt="Icône" />
                </div>
                <div class="service-info">
                  <p>Systèmes de Navigation GPS</p>
                </div>
              </div>
              <div class="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                <div class="service-img">
                  <img src="assets/img/icons/service-02.svg" alt="Icône" />
                </div>
                <div class="service-info">
                  <p>Point d'Accès Wi-Fi</p>
                </div>
              </div>
              <div class="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                <div class="service-img">
                  <img src="assets/img/icons/service-03.svg" alt="Icône" />
                </div>
                <div class="service-info">
                  <p>Sièges de Sécurité pour Enfants</p>
                </div>
              </div>
              <div class="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                <div class="service-img">
                  <img src="assets/img/icons/service-04.svg" alt="Icône" />
                </div>
                <div class="service-info">
                  <p>Options de Carburant</p>
                </div>
              </div>
              <div class="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                <div class="service-img">
                  <img src="assets/img/icons/service-05.svg" alt="Icône" />
                </div>
                <div class="service-info">
                  <p>Assistance Routière</p>
                </div>
              </div>
              <div class="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                <div class="service-img">
                  <img src="assets/img/icons/service-06.svg" alt="Icône" />
                </div>
                <div class="service-info">
                  <p>Radio Satellite</p>
                </div>
              </div>
              <div class="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                <div class="service-img">
                  <img src="assets/img/icons/service-07.svg" alt="Icône" />
                </div>
                <div class="service-info">
                  <p>Accessoires Supplémentaires</p>
                </div>
              </div>
              <div class="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                <div class="service-img">
                  <img src="assets/img/icons/service-08.svg" alt="Icône" />
                </div>
                <div class="service-info">
                  <p>Enregistrement/Sortie Express</p>
                </div>
              </div>
            </div>
          </div>
        </div>
                <!-- Listing Section -->
        <div class="review-sec mb-0">
          <div class="review-header">
            <h4>Description</h4>
          </div>
          <div class="description-list">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <p>
              It was popularised in the 1960s with the release of Letraset
              sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including
              versions of Lorem Ipsum.It was popularised in the 1960s with the
              release of Letraset sheets containing Lorem Ipsum passages, and
              more recently with desktop publishing software like Aldus
              PageMaker including versions of Lorem Ipsum.
            </p>
            <p>
              It was popularised in the 1960s with the release of Letraset
              sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including
              versions of Lorem Ipsum.
            </p>
            <div class="read-more">
              <div class="more-text">
                <p>
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </div>
              <a href="javascript:void(0);" class="more-link">Voir Plus</a>
            </div>
          </div>
        </div>
        <!-- /Listing Section -->
        <!--Listing Features Section-->
        <div class="review-sec specification-card">
          <div class="review-header">
            <h4>Spécifications</h4>
          </div>
          <div class="card-body">
            <div class="lisiting-featues">
              <div class="row">
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-1.svg" alt="Icône Carrosserie" />
                  </div>
                  <div class="featues-info">
                    <span>Carrosserie</span>
                    <h6>Berline</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-2.svg" alt="Icône Marque" />
                  </div>
                  <div class="featues-info">
                    <span>Marque</span>
                    <h6>Nissan</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-3.svg" alt="Icône Transmission" />
                  </div>
                  <div class="featues-info">
                    <span>Transmission</span>
                    <h6>Automatique</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-4.svg" alt="Icône Type de carburant" />
                  </div>
                  <div class="featues-info">
                    <span>Carburant</span>
                    <h6>Diesel</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-5.svg" alt="Icône Kilométrage" />
                  </div>
                  <div class="featues-info">
                    <span>Kilométrage</span>
                    <h6>16 Km</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-6.svg" alt="Icône Transmission" />
                  </div>
                  <div class="featues-info">
                    <span>Transmission</span>
                    <h6>Roues Avant</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-7.svg" alt="Icône Année" />
                  </div>
                  <div class="featues-info">
                    <span>Année</span>
                    <h6>2018</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-8.svg" alt="Icône Climatisation" />
                  </div>
                  <div class="featues-info">
                    <span>Climatisation</span>
                    <h6>Air Conditionné</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-9.svg" alt="Icône VIN" />
                  </div>
                  <div class="featues-info">
                    <span>VIN</span>
                    <h6>45456444</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-10.svg" alt="Icône Portes" />
                  </div>
                  <div class="featues-info">
                    <span>Portes</span>
                    <h6>4 Portes</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-11.svg" alt="Icône Freins" />
                  </div>
                  <div class="featues-info">
                    <span>Freins</span>
                    <h6>ABS</h6>
                  </div>
                </div>
                <div class="featureslist d-flex align-items-center col-lg-3 col-md-4">
                  <div class="feature-img">
                    <img src="assets/img/specification/specification-icon-12.svg" alt="Icône Moteur" />
                  </div>
                  <div class="featues-info">
                    <span>Moteur (CV)</span>
                    <h6>3,000</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="review-sec listing-feature">
          <div class="review-header">
            <h4>Caractéristiques</h4>
          </div>
          <div class="listing-description">
            <div class="row">
              <div class="col-md-4">
                <ul>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Climatisation multi-zone
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Sièges avant chauffants
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Android Auto
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Système de navigation
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Système audio premium
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Bluetooth
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Démarrage sans clé
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Siège à mémoire
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>6 Cylindres
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Régulateur de vitesse adaptatif
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>Essuie-glaces intermittents
                  </li>
                  <li>
                    <span><i class="fa-solid fa-check-double"></i></span>4 vitres électriques
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
                <!--/Listing Features Section-->

        <!-- FAQ -->
        <div class="review-sec faq-feature">
          <div class="review-header">
            <h4>FAQ</h4>
          </div>
          <div class="faq-info">
            <div class="faq-card">
              <h4 class="faq-title">
                <a class="collapsed" data-bs-toggle="collapse" href="#faqOne" aria-expanded="false">
                  Quel âge dois-je avoir pour louer une voiture ?
                </a>
              </h4>
              <div id="faqOne" class="card-collapse collapse">
                <p>
                  Nous proposons une flotte variée de véhicules pour répondre à tous les besoins, y compris des citadines, des berlines, des SUV et des véhicules de luxe. 
                  Vous pouvez consulter notre sélection en ligne ou nous contacter pour vous aider à choisir le véhicule adapté à vos besoins.
                </p>
              </div>
            </div>
            <div class="faq-card">
              <h4 class="faq-title">
                <a class="collapsed" data-bs-toggle="collapse" href="#faqTwo" aria-expanded="false">
                  Quels documents sont nécessaires pour louer une voiture ?
                </a>
              </h4>
              <div id="faqTwo" class="card-collapse collapse">
                <p>
                  Nous proposons une flotte variée de véhicules pour répondre à tous les besoins, y compris des citadines, des berlines, des SUV et des véhicules de luxe. 
                  Vous pouvez consulter notre sélection en ligne ou nous contacter pour vous aider à choisir le véhicule adapté à vos besoins.
                </p>
              </div>
            </div>
            <div class="faq-card">
              <h4 class="faq-title">
                <a class="collapsed" data-bs-toggle="collapse" href="#faqThree" aria-expanded="false">
                  Quels types de véhicules sont disponibles à la location ?
                </a>
              </h4>
              <div id="faqThree" class="card-collapse collapse">
                <p>
                  Nous proposons une flotte variée de véhicules pour répondre à tous les besoins, y compris des citadines, des berlines, des SUV et des véhicules de luxe. 
                  Vous pouvez consulter notre sélection en ligne ou nous contacter pour vous aider à choisir le véhicule adapté à vos besoins.
                </p>
              </div>
            </div>
            <div class="faq-card">
              <h4 class="faq-title">
                <a class="collapsed" data-bs-toggle="collapse" href="#faqFour" aria-expanded="false">
                  Puis-je louer une voiture avec une carte de débit ?
                </a>
              </h4>
              <div id="faqFour" class="card-collapse collapse">
                <p>
                  Nous proposons une flotte variée de véhicules pour répondre à tous les besoins, y compris des citadines, des berlines, des SUV et des véhicules de luxe. 
                  Vous pouvez consulter notre sélection en ligne ou nous contacter pour vous aider à choisir le véhicule adapté à vos besoins.
                </p>
              </div>
            </div>
          </div>
        </div>
                <!-- /FAQ -->
        <!-- Policies -->
        <div class="review-sec">
          <div class="review-header">
            <h4>À savoir</h4>
          </div>
          <div class="policy-list">
            <div class="policy-item">
              <div class="policy-info">
                <h6>Frais d'annulation</h6>
                <p>Des frais d'annulation seront appliqués conformément à la politique</p>
              </div>
              <a class="d-none" [routerLink]="routes.privacyPolicy">En savoir plus</a>
            </div>
            <div class="policy-item">
              <div class="policy-info">
                <h6>Politique</h6>
                <p>
                  J'accepte par la présente les termes et conditions du contrat de
                  location avec l'hôte
                </p>
              </div>
              <a class="d-none" [routerLink]="routes.privacyPolicy">Voir les détails</a>
            </div>
          </div>
                  </div>
        <!-- /Policies -->
        <!-- Reviews -->
        <div class="review-sec listing-review">
          <div class="review-header">
            <h4>Avis des Conducteurs</h4>
          </div>
          <div class="rating-wrapper">
            <div class="rating-wraps">
              <i class="fas fa-star filled"></i>
              <h2>4,7</h2>
              <div class="rating-overall">
                <p>Note Globale</p>
                <p>Basée sur <span>2 Avis</span></p>  
              </div>
            </div>
          </div>
          <div class="review-card">
            <div class="review-head">
              <h6>2 Avis Clients</h6>
            </div>
            <ul>
              <li>
                <div class="review-wraps wrap-card">
                  <div class="review-header-group">
                    <div class="review-widget-header">
                      <span class="review-widget-img">
                        <img src="assets/img/profiles/avatar-16.jpg" class="img-fluid" alt="Utilisateur" />
                      </span>
                      <div class="review-design">
                        <h6>Hamid</h6>
                        <p>Le 25 mars 2024</p>
                      </div>
                    </div>
                    <div class="reviewbox-list-rating">
                      <p>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                      </p>
                    </div>
                  </div>
                  <p>
                    C'est vraiment facile à utiliser et correspond exactement à ce que je recherchais. Beaucoup de modèles esthétiques et hautement personnalisables. Le support en direct est utile et a résolu mon problème en un rien de temps.                  </p>
                  <div class="review-reply">
                    <div class="review-action">
                      <a href="javascript:void(0);"><i class="fa-regular fa-thumbs-up"></i>10</a>
                      <a href="javascript:void(0);"><i class="fa-regular fa-thumbs-down"></i>12</a>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="review-wraps wrap-card">
                  <div class="review-header-group">
                    <div class="review-widget-header">
                      <span class="review-widget-img">
                        <img src="assets/img/profiles/avatar-17.jpg" class="img-fluid" alt="Utilisateur" />
                      </span>
                      <div class="review-design">
                        <h6>Hamida</h6>
                        <p>Le 25 mars 2024</p>
                      </div>
                    </div>
                    <div class="reviewbox-list-rating">
                      <p>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star"></i>
                      </p>
                    </div>
                  </div>
                  <p>
                    C'est vraiment facile à utiliser et correspond exactement à ce que je recherchais. Beaucoup de modèles esthétiques et hautement personnalisables. Le support en direct est utile et a résolu mon problème en un rien de temps.                  </p>
                  <div class="review-reply">
                    <div class="review-action">
                      <a href="javascript:void(0);"><i class="fa-regular fa-thumbs-up"></i>10</a>
                      <a href="javascript:void(0);"><i class="fa-regular fa-thumbs-down"></i>12</a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
                <!-- /Reviews -->

        <!-- Leave a Reply -->
        <div class="review-sec leave-reply-form mb-0">
          <div class="review-header">
            <h4>Laisser un Avis</h4>
          </div>
          <div class="review-list-rating">
            <div class="row">
              <div class="col-12">
                <div class="set-rating">
                  <div class="rating-selection">
                    <input type="checkbox" id="clean1" value="1" />
                    <label for="clean1"></label>
                    <input type="checkbox" id="clean2" value="2" />
                    <label for="clean2"></label>
                    <input type="checkbox" id="clean3" value="3" />
                    <label for="clean3"></label>
                    <input type="checkbox" id="clean4" value="4" />
                    <label for="clean4"></label>
                    <input type="checkbox" id="clean5" value="5" />
                    <label for="clean5"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="review-list">
              <ul>
                <li class="review-box feedbackbox mb-0">
                  <div class="review-details">
                    <form class="#">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="input-block">
                            <label>Commentaire </label>
                            <textarea rows="4" class="form-control"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="submit-btn text-end">
                        <button class="btn btn-secondary submit-review" type="submit">
                          Envoyer
                        </button>
                      </div>
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Leave a Reply -->
      </div>
      <div class="col-lg-4 theiaStickySidebar">
        <div class="stickybar">
          <div class="review-sec mt-0">
            <div class="review-header">
              <h4>Détails des Tarifs</h4>
            </div>
            <div class="price-list">
              <div class="price-item">
                <p>Par jour (8 heures)</p>
                <h6>300 €</h6>
              </div>
              <div class="text-end">
                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#fare_details" class="fare-link">
                  <i class="feather icon-file-text"></i>Résumé des Tarifs
                </a>
              </div>
            </div>
          </div>
          <div class="review-sec mt-0">
            <div class="review-header">
              <h4>Vérifier la disponibilité</h4>
            </div>
            <div class="location-content">
              <div class="tab-content">
                <div class="tab-pane fade active show" id="delivery">
                  <form class="">
                    <ul>
                      <li class="column-group-main">
                        <div class="input-block m-0">
                          <label>Date de prise en charge</label>
                        </div>
                        <div class="input-block-wrapp sidebar-form">
                          <div class="input-block me-lg-2">
                            <div class="group-img">
                              <div class="form-wrap">
                                <input type="text" class="form-control datetimepicker" bsDatepicker
                                  placeholder="04/11/2023" />
                                <span class="form-icon">
                                  <i class="fa-regular fa-calendar-days"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="column-group-main">
                        <div class="input-block m-0">
                          <label>Date de retour</label>
                        </div>
                        <div class="input-block-wrapp sidebar-form">
                          <div class="input-block me-lg-2">
                            <div class="group-img">
                              <div class="form-wrap">
                                <input type="text" class="form-control datetimepicker" bsDatepicker
                                  placeholder="04/11/2023" />
                                <span class="form-icon">
                                  <i class="fa-regular fa-calendar-days"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="column-group-last">
                        <div class="input-block mb-0">
                          <div class="message-btn">
                            <a [routerLink]="routes.bookingCheckout"
                              class="btn btn-primary check-available w-100">Réserver</a>
                            <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#enquiry"
                              class="btn btn-order">En savoir plus</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
          </div>
                    <div class="review-sec extra-service mt-0">
            <div class="review-header">
              <h4>Détails du propriétaire</h4>
            </div>
            <div class="owner-detail">
              <div class="owner-img">
                <a href="javascript:void(0);"><img src="assets/img/cars/car-01-slide3.jpg" alt="Utilisateur" /></a>
                <span class="badge-check"><img src="assets/img/icons/badge-check.svg" alt="Utilisateur" /></span>
              </div>
              <div class="reviewbox-list-rating">
                <h5><a>Hamid Cars</a></h5>
                <p>
                  <i class="fas fa-star filled"></i>
                  <span class="d-inline-block average-list-rating fw-medium">3.5</span>
                  <span class="d-inline-block average-list-rating">(234)</span>    
                </p>
              </div>
            </div>
            <ul class="booking-list">
              <li>
                Email
                <span>info&#64;example.com</span>
              </li>
              <li>
                Téléphone
                <span>+212 676545565</span>
              </li>
              <li>
                Localisation
                <span>4635 Pheasant Ridge Road, Ville d'Hollywood, États-Unis</span>
              </li>
            </ul>
            <div class="message-btn">
              <a href="javascript:void(0);" class="btn btn-order">Consulter l'agence</a>
            </div>
          </div>
                    <div class="review-sec share-car mt-0">
            <div class="review-header">
              <h4>Voir la localisation</h4>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
              class="iframe-video"></iframe>
          </div>
          <div class="review-sec share-car mt-0 mb-0">
            <div class="review-header">
              <h4>Partager</h4>
            </div>
            <ul class="nav-social">
              <li>
                <a href="javascript:void(0)"><i class="fa-brands fa-facebook-f fa-facebook fi-icon"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="fab fa-instagram fi-icon"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="fab fa-twitter fi-icon"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal custom-modal fade check-availability-modal" id="pages_edit" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <div class="form-header text-start mb-0">
          <h4 class="mb-0 text-dark fw-bold">Availability Details</h4>
        </div>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span class="align-center" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="available-for-ride">
              <p>
                <i class="fa-regular fa-circle-check"></i>Chevrolet Camaro is
                available for a ride
              </p>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="row booking-info">
              <div class="col-md-4 pickup-address">
                <h5>Pickup</h5>
                <p>45, 4th Avanue Mark Street USA</p>
                <span>Date & time : 11 Jan 2023</span>
              </div>
              <div class="col-md-4 drop-address">
                <h5>Drop Off</h5>
                <p>78, 10th street Laplace USA</p>
                <span>Date & time : 11 Jan 2023</span>
              </div>
              <div class="col-md-4 booking-amount">
                <h5>Booking Amount</h5>
                <h6><span>$300 </span> /day</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="booking-info seat-select">
              <h6>Extra Service</h6>
              <label class="custom_check">
                <input type="checkbox" name="rememberme" class="rememberme" />
                <span class="checkmark"></span>
                Baby Seat - <span class="ms-2">$10</span>
              </label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="booking-info pay-amount">
              <h6>Deposit Option</h6>
              <div class="radio radio-btn">
                <label> <input type="radio" name="radio" /> Pay Deposit </label>
              </div>
              <div class="radio">
                <label> <input type="radio" name="radio" /> Full Amount </label>
              </div>
            </div>
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <div class="booking-info service-tax">
              <ul>
                <li>Booking Price <span>$300</span></li>
                <li>Extra Service <span>$10</span></li>
                <li>Tax <span>$5</span></li>
              </ul>
            </div>
            <div class="grand-total">
              <h5>Grand Total</h5>
              <span>$315</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a [routerLink]="routes.bookingList" class="btn btn-back" data-bs-dismiss="modal">Go to Details<i
            class="fa-solid fa-arrow-right"></i></a>
      </div>
    </div>
  </div>
</div>
<!-- /Modal -->
<app-footer></app-footer>
