import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { brand } from 'src/app/models/brand';
import { Car, Coordinates } from 'src/app/models/car';
import { CarFilter } from 'src/app/models/carFilter';
import { fuelTypes } from 'src/app/models/fuelType';
import { transmissions } from 'src/app/models/transmission';
import { CarService } from 'src/app/shared/services/car.service';
import { LocationService } from 'src/app/shared/services/location.service';

@Component({
  selector: 'app-listing-grid',
  templateUrl: './car-list.component.html',
  styleUrl: './car-list.component.scss',
})
export class ListingGridComponent implements OnInit, OnDestroy {
  private rentingStartDate: Date | undefined;
  private rentingEndDate: Date | undefined;
  private city:string = ''; 

  currentLocation: GeolocationPosition | null = null;
  capacities: number[] = [2, 4, 5, 7];

  brands: string[] = brand;
  transmissions: string[] = transmissions;
  fuelTypes: string[] = fuelTypes;
  cars: Car[] = [];
  totalCount: number = 0;
  page = 0;
  size = 9;
  totalPages: number = 0;

  errorMessage: string | undefined;

  watchId: number | null = null;

  filterForm!: FormGroup;

  sortByList: { label: string; value: string }[] = [
    { label: 'Newest', value: 'dateAdded_desc' },
    // { label: 'Relevance', value: 'relevance' },
    { label: 'High to Low', value: 'pricePerDay_desc' },
    { label: 'Low to High', value: 'pricePerDay_asc' },
    // { label: 'Best Rated', value: 'rate_desc' },
    // { label: 'Distance', value: 'distance' },
    // { label: 'Popularity', value: 'popularity_desc' },
  ];

  sortByControl: FormControl = new FormControl('dateAdded_desc');
  private initialized = false;

  private carFilter: CarFilter = {
    capacities: [],
    fuelType: [],
    lux: null,
    maxPrice: 35000,
    minPrice: 100,
    searchQuery: '',
    city: '',
    sortBy: this.sortByControl.value,
    selectedBrands: [],
    transmission: [],
  };

  constructor(
    private readonly carService: CarService,
    private readonly locationService: LocationService,
    private readonly spinner: NgxSpinnerService,
    private readonly fb: FormBuilder,
    private readonly router: ActivatedRoute,
    private readonly route:Router
  ) {
    this.filterForm = this.fb.group({
      searchQuery: [''],
      minPrice: 100,
      maxPrice: 35000,
      luxe: [false],
      selectedBrands: this.fb.array([]),
      city: [''],
      fuelType: this.fb.control([]),
      capacities: this.fb.array([]),
      sortBy:this.sortByControl.value,
      transmission: this.fb.control([]),
    });
  }

  ngOnInit(): void {
    
    
    this.router.queryParamMap.subscribe((params) => {
      this.city =  params.get('city') || '';

      this.rentingStartDate = params.get('startDate')
        ? new Date(params.get('startDate')!)
        : undefined;

      this.rentingEndDate = params.get('endDate')
        ? new Date(params.get('endDate')!)
        : undefined;

      // Update the form with query params if available
      const city = this.city; 
      
      this.filterForm.patchValue({
        city,
      });

      // Trigger search logic during initialization
      if (!this.initialized) {
        this.initialized = true;
        this.updateCarFilter();
        if (this.city) {
          // If query parameters exist, trigger /search
          this.search(true);
        } else {
          // If no query parameters, trigger a default /filter search
          this.search(false);
        }
      }
    });

    // Listen to form changes after initialization
    this.filterForm.valueChanges
      .pipe(
        debounceTime(300), // Avoid rapid firing of requests
        distinctUntilChanged() // Trigger only on actual value changes
      )
      .subscribe(() => {
        if (this.initialized) {
          this.page = 0; //init page to 0 when filter change; 
          this.updateCarFilter();
          this.search(false); // Trigger /filter for form changes
        }
      });

      this.sortByControl.valueChanges.subscribe(sortBy => {
          this.filterForm.get('sortBy')?.setValue(sortBy)
      })
  }

  private updateCarFilter(): void {
    const filterForm = this.filterForm.value;

    // Map form values to CarFilterDTO object
    this.carFilter = {
      searchQuery: filterForm.searchQuery || undefined,
      minPrice: filterForm.minPrice,
      maxPrice: filterForm.maxPrice,
      lux: filterForm.luxe,
      selectedBrands: filterForm.selectedBrands || [],
      city: filterForm.city || undefined,
      sortBy:this.sortByControl.value,
      fuelType: filterForm.fuelType || [],
      capacities: filterForm.capacities || [],
      transmission: filterForm.transmission || [],
    };
  }

  private search(isSearch: boolean = false): void {
    if (isSearch) {
      this.loadSearchedCars();
      this.loadTotalCountSearched();
    } else {
      this.loadFilteredCars();
      this.loadTotalCountFiltered();
    }
  }


  navigateToCarDetails(carId: string){
    console.log("navigating to "+carId);
    
    this.route.navigate(['/car-detail',carId])
  }

  ngOnDestroy(): void {
    this.watchId !== null
      ? this.locationService.clearWatch(this.watchId)
      : null;
  }

  getUserLocation() {
    this.locationService.getCurrentPosition().subscribe(
      (position) => {
        this.currentLocation = position;
        this.startTrackingLocation();
      },
      (error) => {
        this.errorMessage = 'Could not get user location';
        console.error(error);
      }
    );
  }

  loadFilteredCars() {
    this.spinner.show();
    this.carService
      .findFilteredAllCars(
        this.page,
        this.size,
        this.sortByControl.value,
        this.carFilter
      )
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((data) => (this.cars = data));
  }

  loadSearchedCars() {
    this.spinner.show();
    this.carService
      .findSearchedAllCars(
        this.page,
        this.size,
        this.sortByControl.value,
        this.carFilter
      )
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((data) => (this.cars = data));
  }

  loadTotalCountSearched(): void {
    this.spinner.show();
    this.carService
      .getTotalSearchedCarCount(this.carFilter)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((count) => {
        this.totalCount = count;
        this.totalPages = Math.max(
          0,
          Math.floor((this.totalCount - 1) / this.size)
        );
      });
  }

  loadTotalCountFiltered(): void {
    this.spinner.show();
    this.carService
      .getTotalFilteredCarCount(this.carFilter)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((count) => {
        this.totalCount = count;
        this.totalPages = Math.max(
          0,
          Math.floor((this.totalCount - 1) / this.size)
        );
      });
  }

  onFuelTypeChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const selectedFuelTypes = this.filterForm.get('fuelType')!.value;
    if (checkbox.checked) {
      selectedFuelTypes.push(checkbox.value);
    } else {
      const index = selectedFuelTypes.indexOf(checkbox.value);
      if (index > -1) {
        selectedFuelTypes.splice(index, 1);
      }
    }
    this.filterForm.get('fuelType')!.setValue(selectedFuelTypes);
  }

  onTransmissionChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const selectedTransmission = this.filterForm.get('transmission')!.value;
    if (checkbox.checked) {
      selectedTransmission.push(checkbox.value);
    } else {
      const index = selectedTransmission.indexOf(checkbox.value);
      if (index > -1) {
        selectedTransmission.splice(index, 1);
      }
    }
    this.filterForm.get('transmission')!.setValue(selectedTransmission);
  }

  // Method to create an array from 0 to a given number
  createArrayFromNumber(n: number): number[] {
    return Array.from({ length: n + 1 }, (_, index) => index);
  }

  getDistanceBetweenCarAndUser(coordinates: Coordinates): number | string {
    if (!!coordinates)
      return this.locationService.calculateDistance(
        parseFloat(coordinates.latitude),
        parseFloat(coordinates.longitude),
        this.currentLocation?.coords.latitude!,
        this.currentLocation?.coords.longitude!
      );

    return '?';
  }

  nextPage(): void {
    if (this.page < this.totalPages - 1) {
      this.page++;
      this.loadFilteredCars();
    }
  }

  prevPage(): void {
    if (this.page > 0) {
      this.page--;
      this.loadFilteredCars();
    }
  }

  goToPage(page: number): void {
    this.page = page;
    this.loadFilteredCars();
  }

  startTrackingLocation() {
    this.watchId = this.locationService.watchPosition(
      (position) => {
        this.currentLocation = position;
        console.log('Updated location:', position);
      },
      (error) => {
        console.error('Error tracking location:', error);
      }
    );
  }

  // Calculate the range of items to display (e.g., 1-9, 10-18)
  getDisplayedRange(): string {
    const start = this.page * this.size + 1;
    const end = Math.min((this.page + 1) * this.size, this.totalCount);
    return `${start}-${end}`;
  }

  get selectedBrands(): FormArray {
    return this.filterForm.get('selectedBrands') as FormArray;
  }

  get selectedCapacities(): FormArray {
    return this.filterForm.get('capacities') as FormArray;
  }

  toggleSelection(array: FormArray, value: number | string) {
    const index = array.value.indexOf(value);
    if (index === -1) {
      array.push(this.fb.control(value));
    } else {
      array.removeAt(index);
    }
  }

  carOwlOptions: OwlOptions = {
    items: 4,
    margin: 24,
    nav: true,
    dots: false,
    loop: true,
    rtl: false,
    navText: [
      "<i class='fa-solid fa-arrow-left'></i>",
      "<i class='fa-solid fa-arrow-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1170: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
}
