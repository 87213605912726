import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListingGridComponent } from './components/car-list/car-list.component';
import { HomeComponent } from './components/home/home.component';
//import { AuthGuard } from './guards/auth.guard';
import { ListingDetailsComponent } from './components/car-details/car-details.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'listing',
    component:ListingGridComponent,
    //canActivate:[AuthGuard]
  },
  {
    path: 'car-detail/:id',
    component:ListingDetailsComponent,
    //canActivate:[AuthGuard]
  },

  {
    path: 'authentication',
    loadChildren: () => import('./components/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}