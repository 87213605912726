export const brand :string[]= [ "Ford"
    , "Mercedes"
    , "Fiat"
    , "Toyota"
    , "BMW"
    , "Honda"
    , "Audi"
    , "Chevrolet"
    , "Nissan"
    , "Volkswagen"
    , "Hyundai"
    , "Volvo"
    , "Tesla"
    , "Jaguar"
    , "Porsche"
    , "Subaru"
    , "Mazda"
    , "Mitsubishi"
    , "Lexus"
    , "Suzuki"
    , "Dodge"
    , "Chrysler"
    , "Jeep"
    , "RAM"
    , "AlfaRomeo"
    , "Cadillac"
    , "Buick"
    , "GMC"
    , "Mini"
    , "LandRover"
    , "Peugeot"
    , "Renault"
    , "Citroen"
    , "Skoda"
    , "Seat"
    , "Opel"
    , "Kia"
    , "Infiniti"
    , "Genesis"
    , "Maserati"
    , "Ferrari"
    , "Lamborghini"
    , "Bugatti"
    , "AstonMartin"
    , "McLaren"
    , "RollsRoyce"
    , "Bentley"
    , "Pagani"
    , "Koenigsegg"
    , "Hummer"
    , "Saab"
    , "Lancia"
    , "Scion"
    , "Daihatsu"
    , "Proton"
    , "Tata"
    , "Mahindra"
    , "Holden"
    , "Vauxhall"
    , "SsangYong"
    , "BYD"
    , "Geely"
    , "Chery"
    , "GreatWall"
    , "Nio"
    , "Lucid"
    , "Rivian"
    , "Fisker"
    , "Polestar"
    , "Dacia"
    , "Aixam"
    , "TVR"
    , "Spyker"
    , "Rimac"
    , "Wiesmann"
    , "DeLorean"
    , "Saturn"
    , "Plymouth"
    , "Isuzu"
    , "Studebaker"
    , "Shelby"
    , "Eagle"
    , "Panoz"
    , "Saleen"
    , "Other"];
